import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<div className="work-img">
								<img
									src="./tsmc.png"
									alt="facebook"
									className="work-image"
								/>
							</div>
							<div className="work-description">
								<div className="work-title">Taiwan Semiconductor Manufacturing Company</div>
								<div className="work-subtitle">
								IT intern at Business System Integration Division
								</div>
								<div className="work-duration">Jul, 2023 ~ Aug, 2023</div>
							</div>
						</div>

						<div className="work">
							
							<div className="work-img">
								<img
									src="./microprogram.png"
									alt="twitter"
									className="work-image"
								/>
							</div>
							
							<div className="work-description">
								<div className="work-title">Microprogram Information Co.,Ltd</div>
								<div className="work-subtitle">
								IT trainee at Data Science Department & Firmware Development Department
								</div>
								<div className="work-duration">Oct, 2023 ~ Jun, 2024</div>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
