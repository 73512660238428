import React from "react";
import { faBriefcase, faSchool } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/educations.css";

const educations = () => {
	return (
		<div className="educations">
			<Card
				icon={faSchool}
				title="Education"
				body={
					<div className="educations-body">
						<div className="education">
							<div className="education-img">
								<img
									src="./nchu.png"
									alt="nchu"
									className="education-image"
								/>
							</div>
							<div className="education-description">
								<div className="education-title">National Chung Hsing University</div>
								<div className="education-subtitle">
								Bachelor Program of Electrical Engineering and Computer Science
								</div>
								<div className="education-duration">2022 ~ now</div>
							</div>
						</div>

						<div className="education">
							<div className="education-img">
								<img
									src="./whsh.png"
									alt="whsh"
									className="education-image"
								/>
							</div>
							<div className="education-description">
								<div className="education-title">Taichung Municipal Wen-Hua Senior High School</div>
								<div className="education-subtitle">
								General Education
								</div>
								<div className="education-duration">2019 ~ 2022</div>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default educations;
